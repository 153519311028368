// Header.js
import React from 'react';
import logo from "../assets/logo.png"; // Adjust the import path as needed
import styles from './CustomHeader.module.css';

function Header() {

    const navigateToRadium = () => {
        window.open("https://raydium.io/swap/", "_blank"); 
      };
    
      const navigateToBuy = () => {
        window.open("https://raydium.io/swap/", "_blank"); 
      };
    
  return (
    <header className={`${styles.creativeHeader} flex justify-between items-center p-4`}>
      <img src={logo} alt="XDonK Logo" className={`${styles.logo} w-16 h-16`} />
      <div>
        <button onClick={navigateToRadium} className={`${styles.button} ${styles.radiumButton} mx-2`}>Radium</button>
        <button onClick={navigateToBuy} className={`${styles.button} ${styles.buyButton} mx-2`}>Buy XDonK</button>
      </div>
    </header>
  );
}

export default Header;
