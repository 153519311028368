import React from 'react';
import Header from './components/Header';
import LargeCard from './components/LargeCard';
import SmallCard from './components/SmallCard';
import card1 from "./assets/card1.webp";
import card2 from "./assets/card2.webp";
import './App.css';
import TwitterIcon from "./assets/Twitter.png";
import SendIcon from "./assets/Send.png";
import BarIcon from "./assets/baricon1.png";
import RecIcon from "./assets/recticon.png";
import UniIcon from "./assets/unicorn2.png";


const socialMediaLinks = [
  { icon: <img src={BarIcon} alt="Bar Icon" style={{ width: 24, height: 24 }} />, link: "https://solscan.io/token/G3wqxasCS3cuNHsCifr8QfrkYaoPMQqCRQnwZyTmE6bs" },
  { icon: <img src={RecIcon} alt="Rec Icon" style={{ width: 24, height: 24 }} />, link: "https://solscan.io/token/G3wqxasCS3cuNHsCifr8QfrkYaoPMQqCRQnwZyTmE6bs" },
  { icon: <img src={UniIcon} alt="Uni Icon" style={{ width: 30, height: 30 }} />, link: "https://raydium.io/swap/" },
  { icon: <img src={SendIcon} alt="Send Icon" style={{ width: 24, height: 24 }} />, link: "https://t.me/Portaldonk" },
  { icon: <img src={TwitterIcon} alt="Twitter Icon" style={{ width: 24, height: 24 }} />, link: "https://twitter.com/XDonk_" },
];

function App() {
  return (
    <div className="App">
      <Header />
      <div className="banner">
  <h1>XDonK - The next big memecoin with actual utility.</h1>
  <h2>Laugh and earn - Play and earn.</h2>
</div>

      <main>
        <div className="largeCardLayout">
          <LargeCard 
            imageUrl={card2}
            title="XDonK to the Moon!"
            description="Join the XDonK army and let's ride to the moon together! Our meme coin is not just a coin, it's a movement."
          />
          <LargeCard 
            imageUrl={card1}
            title="Why XDonK?"
            description="Because XDonK is the only coin that combines the power of memes with serious crypto technology. Get in on the fun!"
          />
        </div>
        <div className="smallCardLayout">
        <SmallCard title="Connect with XDonK" description="Follow us across the web:">
  <div className="flex justify-around items-center mt-4">
    {socialMediaLinks.map((social, index) => (
      <a key={index} href={social.link} target="_blank" rel="noopener noreferrer" className="m-2">
        {social.icon}
      </a>
    ))}
  </div>
</SmallCard>

<SmallCard title="Tokenomics" description="Key Metrics:">
  <div className="flex justify-around items-center mt-4 flex-wrap">
    <div className="tokenomics-btn">Supply: 1B</div>
    <div className="tokenomics-btn">Tax: NO</div>
    <div className="tokenomics-btn">Liquidity: TBA</div>
    <div className="tokenomics-btn">Team: 5%</div>
    <div className="tokenomics-btn">Marketing: 5%</div>
  </div>
</SmallCard>



        </div>
      </main>
    </div>
  );
}

export default App;
