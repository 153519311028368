import React from 'react';
import styles from './LargeCard.module.css';

function LargeCard({ title, description, imageUrl }) {
  return (
    <div className={styles.largeCard}>
      <div className={styles.cardImage} style={{ backgroundImage: `url(${imageUrl})` }}></div>
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.cardDescription}>{description}</p>
    </div>
  );
}

export default LargeCard;
