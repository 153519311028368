// SmallCard.js
import React from 'react';
import styles from './SmallCard.module.css'; // The CSS module for SmallCard

function SmallCard({ title, description, children }) {
  return (
    <div className={styles.smallCard}>
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.cardDescription}>{description}</p>
      {children}
    </div>
  );
}

export default SmallCard;
